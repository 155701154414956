<template>
  <div>
    <div v-if="areaImg" class="content">
      <div class="box">
        <div class="top">
          <img class="img1" :src="areaImg" alt />
          <div class="top-phone" @click="tel()">
            <img class="phone" src="@/assets/image/phone.png" alt />
          </div>
          <div class="top-ck" @click="govneue">
            <img src="@/assets/image/地图.png" alt />
            <span>查看全景</span>
          </div>
        </div>
        <div class="bottom">
          <div class="left">
            <div class="title">{{areaTitle}}</div>
            <div class="left-bom">
              <img src="../../assets/image/时间.png" alt />
              <div class="time">开放时间: {{openStartTime}}-{{openEndTime}}</div>
            </div>
          </div>
          <div class="but" @click="gofield">查看详情</div>
        </div>
      </div>
      <van-cell-group inset>
        <van-field label="容纳人数:" readonly>
          <template #input>
            {{capacity}}人
            <div class="pf">
              面积:
              <span>{{measureArea}}㎡</span>
            </div>
          </template>
        </van-field>
        <van-field v-model="facilities" autosize type="textarea" rows="1" label="场馆设施:" readonly />
        <van-field v-model="other" autosize type="textarea" rows="1" label="其他说明:" readonly />
      </van-cell-group>

      <van-cell-group inset>
        <van-field required label="预约时段" readonly>
          <template #input>
            <div class="tet">
              <div @click="seldata">
                <span>{{rq}}</span>
                <img class="tet-img" src="@/assets/image/4.png" alt />
              </div>
              <div @click="seltime">
                <span>{{sj}}</span>
                <img class="tet-img" src="@/assets/image/4.png" alt />
              </div>
            </div>
          </template>
        </van-field>
        <van-field required label="预约类型" readonly>
          <template #input>
            <div class="tet" @click="selectype">
              <span>{{lx}}</span>
              <img src="@/assets/image/4.png" alt />
            </div>
          </template>
        </van-field>
        <van-field required label="预约设备" readonly>
          <template #input>
            <div class="tet" @click="selecFacility">
              <span>{{sb}}</span>
              <img src="@/assets/image/4.png" alt />
            </div>
          </template>
        </van-field>
      </van-cell-group>

      <van-cell-group inset>
        <van-field v-model="subject" required label="主题" placeholder="请填写会议或活动主题" />
        <van-field v-model="masterCompany" required label="主办单位" placeholder="单位/公司名称" />
        <van-field v-model="slaveCompany" label="协办单位" placeholder="单位/公司名称" />
        <van-field v-model="leader" label="出席领导" placeholder="请填写出席领导" />
        <van-field v-model="attendance" required label="出席人数" placeholder="请输入人数" />
      </van-cell-group>

      <van-cell-group inset>
        <van-field v-model="name" required label="联系人" placeholder="请输入联系人" />
        <van-field v-model="phone" required label="联系电话" placeholder="请输入联系电话" />
        <van-field v-model="company" required label="公司/部门" placeholder="请输入公司/部门" />
        <van-field v-model="emil" label="电子邮箱" placeholder="请输入电子邮箱" />
      </van-cell-group>
      <van-cell-group inset class="updata">
        <van-cell label="请上传小于15M的文件;预约路演必须上传路演PPT等文件">
          <template #title>
            <div>
              <span class="xxx" v-if="isFile===1?true:false">*</span>上传资料(可上传多份)
            </div>
          </template>
        </van-cell>
        <div>
          <div style="position: relative" v-for="(item,index) in filedata" :key="index">
            <van-icon color="#666" @click.stop="shanchu(index)" class="dele" name="cross" />
            <a class="file-box filename" :href="item">
              <img src="../../assets/image/文件.png" alt />
              <span class="filename">{{item}}</span>
            </a>
          </div>
          <van-uploader
            accept
            :max-size="15 * 1024* 1024"
            @oversize="onOversize"
            :before-read="beforeRead"
            :after-read="afterRead"
          >
            <div class="up">
              <van-icon name="plus" size="20px" />
            </div>
          </van-uploader>
        </div>
      </van-cell-group>

      <div class="message">
        <span style="font-size:16px">备注说明/留言</span>
        <van-field
          v-model="message"
          rows="2"
          autosize
          type="textarea"
          maxlength="100"
          placeholder="请输入备注说明或者留言"
          show-word-limit
        />
      </div>
      <div class="btn" @click="submitbooked">确认以上信息，并提交</div>
      <van-action-sheet v-model="show3" title="选择日期">
        <div>
          <van-datetime-picker
            v-model="currentDate"
            type="date"
            :min-date="minDate"
            :max-date="maxDate"
            visible-item-count="5"
          >
            <template #default>
              <div></div>
            </template>
          </van-datetime-picker>
          <div class="date-bottom">
            <div class="no" @click="show3 = false">取 消</div>
            <div @click="submittime">确 定</div>
          </div>
        </div>
      </van-action-sheet>
      <van-action-sheet v-model="show" title="预约类型">
        <div class="tent">
          <div class="tent-box">
            <div
              v-for="(item,index) in bookedtype"
              :key="index"
              @click="seltype(index)"
              :class="item.sel?'sel':''"
            >{{item.value}}</div>
          </div>
          <div class="tent-but" @click="submittype">确 定</div>
        </div>
      </van-action-sheet>
      <van-action-sheet v-model="show1" title="预约设备">
        <div class="tent">
          <div class="tent-box">
            <div
              v-for="(item,index) in facility"
              :key="index"
              @click="selfacility(index)"
              :class="item.sel?'sel':''"
            >{{item.value}}</div>
          </div>
          <div class="tent-but" @click="submitfacility">确 定</div>
        </div>
      </van-action-sheet>
      <van-action-sheet v-model="show4" title="预约时段">
        <div class="tent">
          <div class="time-top">(一小时起订，灰色为已被预订)</div>
          <div class="rqsj">{{rq+' '+rqsj}}</div>
          <div class="tent-box">
            <div
              :id="item.status?'hz':''"
              class="box-time"
              v-for="(item,index) in datetime"
              :key="index"
              :class="item.sell?'sell':''"
              @click="xztime(index)"
            >{{item.time}}</div>
          </div>
          <div class="date-bottom">
            <div class="timebut no" @click="show4 = false">取 消</div>
            <div class="timebut" @click="subhmm">确 定({{time}}小时)</div>
          </div>
        </div>
      </van-action-sheet>
    </div>
    <van-loading v-else size="24px" style="text-align:center;margin-top:70px">加载中...</van-loading>
  </div>
</template>

<script>
import moment from 'moment'
import {
  getSubscribeTypeList,
  getAreaSubscribeDetail,
  file,
  getSubscribeTime,
  subscribeArea
} from '@/api/user'
import axios from 'axios'
import { Toast } from 'vant'
export default {
  data() {
    return {
      timeindex: '',
      rqsj: '',
      leader: '',
      attendance: '',
      rq: '请选择日期',
      sj: '请选择时间段',
      capacity: '',
      measureArea: '',
      openStartTime: '',
      openEndTime: '',
      facilities: '',
      areaTitle: '',
      areaImg: '',
      currentDate: '',
      minDate: '',
      maxDate: '',
      bookedtype: [],
      facility: [],
      datetime: [],
      show: false,
      show1: false,
      show3: false,
      show4: false,
      lx: '请选择类型',
      sb: '请选择使用设备',
      other: '',
      masterCompany: '',
      subject: '',
      name: '',
      phone: '',
      company: '',
      emil: '',
      message: '',
      time: 0,
      id: '',
      filedata: [],
      endTime: '',
      startTime: '',
      slaveCompany: '',
      ww: false,
      areaPhone: '',
      open: true,
      isFile: '',
      link: ''
    }
  },
  created() {
    this.id = this.$route.query.id
    this.currentDate = new Date()
    this.minDate = new Date()
    this.maxDate = new Date(2032, 10, 1)
    this.getAreaSubscribeDetail()
  },
  watch: {
    ww: {
      handler() {
        let a = 0
        let end = ''
        this.datetime.forEach((res, index) => {
          if (res.sell) {
            a = a + 1
            end = index
          }
        })
        const sta = this.datetime.findIndex(res => {
          return res.sell
        })
        if (a >= 2) {
          const arr = this.datetime.slice(sta, end + 1)
          let flag = false
          arr.forEach(res => {
            if (res.status === 1) {
              flag = true
            }
          })
          if (flag) {
            Toast('请选择连续时间段')
            this.datetime.forEach(res => {
              res.sell = false
            })
            this.time = 0
            this.rqsj = ''
          } else {
            let a = -0.5
            this.datetime.forEach((res, index) => {
              if (index >= sta && index <= end) {
                res.sell = true
              }
            })
            this.datetime.forEach(res => {
              if (res.sell) {
                a = a + 0.5
              }
            })
            this.rqsj = this.datetime[sta].time + '-' + this.datetime[end].time
            this.time = a
          }
        } else {
          this.rqsj = ''
          this.time = 0
        }
      },
      deep: true
    }
  },
  methods: {
    govneue() {
      localStorage.setItem('flag', 0)
      this.$router.push(`/ifm?link=${this.link}`)
    },
    shanchu(index) {
      this.filedata.splice(index, 1)
    },
    gofield() {
      localStorage.setItem('title', this.areaTitle)
      this.$router.push(`/textfield?id=${this.id}&flag=1`)
    },
    tel() {
      window.location.href = 'tel:' + this.areaPhone
    },
    async throttle(obj) {
      if (this.open === true) {
        this.open = false
        const { data } = await subscribeArea(obj)
        if (data.code === 200) {
          this.open = true
          Toast.success('预约成功')
          this.$router.push('/appointment')
        } else {
          this.open = true
          Toast.success(data.msg)
        }
      } else {
        Toast.fail('请勿重复提交表单')
      }
    },
    async submitbooked() {
      const phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
      if (this.rq === '请选择日期' || this.sj === '请选择时间段') {
        Toast('请选择预约时段')
      } else if (this.lx === '请选择类型') {
        Toast('请选择预约类型')
      } else if (this.sb === '请选择使用设备') {
        Toast('请选择预约设备')
      } else if (!this.subject) {
        Toast('请填写主题')
      } else if (!this.masterCompany) {
        Toast('请填写主办单位')
      } else if (!this.attendance) {
        Toast('请填写出席人数')
      } else if (!this.name) {
        Toast('请填写联系人')
      } else if (!this.phone) {
        Toast('请填写联系电话')
      } else if (!this.company) {
        Toast('请填写公司部门')
      } else if (this.filedata.length === 0 && this.isFile === 1) {
        Toast('请上传资料')
      } else if (!phonereg.test(this.phone)) {
        Toast('手机号格式不正确')
      } else {
        let str = ''
        this.filedata.forEach(res => {
          str = str + res + ';'
        })
        const obj = {
          areaId: this.id,
          attendance: this.attendance,
          chooseFacilities: this.sb,
          contact: this.name,
          department: this.company,
          email: this.emil,
          startTime: this.rq + ' ' + this.startTime,
          endTime: this.rq + ' ' + this.endTime,
          leader: this.leader,
          masterCompany: this.masterCompany,
          phone: this.phone,
          remarks: this.message,
          slaveCompany: this.slaveCompany,
          subject: this.subject,
          subscribeData: str,
          subscribeType: this.lx
        }
        const obj1 = {}
        for (const k in obj) {
          if (obj[k]) {
            obj1[k] = obj[k]
          }
        }
        this.throttle(obj1)
      }
    },
    subhmm() {
      if (this.time < 1) {
        Toast.fail('一小时起订')
      } else if (this.time > 8) {
        Toast.fail('单次预约不得超过8小时')
      } else {
        const startTime = this.datetime.find(res => {
          return res.sell === true
        })
        const arr = []
        this.datetime.forEach(res => {
          if (res.sell) {
            arr.push(res)
          }
        })
        this.endTime = arr[arr.length - 1].time
        this.startTime = startTime.time
        console.log(this.startTime, this.endTime)
        this.show4 = false
        this.sj = this.startTime + '-' + this.endTime
      }
    },
    async submittime() {
      this.rq = moment(this.currentDate).format('YYYY-MM-DD')
      this.show3 = false
      const { data } = await getSubscribeTime({
        areaId: this.id,
        date: this.rq,
        orderType: 0
      })
      console.log(data, 111111111111)
      if (data.code === 400) {
        this.rq = '请选择日期'
        Toast('当日不开放')
      } else {
        let date = new Date()
        const aaa = moment(date).format('YYYY-MM-DD')
        date = String(date)
          .substring(16)
          .substring(0, 2)
        data.data.forEach(res => {
          if (res.status === 0) {
            res.sell = false
          }
          if (
            Number(res.time.substring(0, 2)) <= Number(date) &&
            aaa === this.rq
          ) {
            res.status = 1
          }
        })
        this.sj = '请选择时间段'
        this.datetime = data.data
      }
    },
    onOversize() {
      Toast.file('文件大小不能超过 15m')
    },
    beforeRead(a) {
      console.log(a.name.split('.')[1])
      if (
        a.name.split('.')[1] === 'pdf' ||
        a.name.split('.')[1] === 'doc' ||
        a.name.split('.')[1] === 'docx' ||
        a.name.split('.')[1] === 'pdf' ||
        a.name.split('.')[1] === 'xlsx' ||
        a.name.split('.')[1] === 'xls' ||
        a.name.split('.')[1] === 'pptx' ||
        a.name.split('.')[1] === 'ppt'
      ) {
        return true
      } else {
        Toast('请上传pdf/wprd/ppt/excel文件')
        return false
      }
    },
    async afterRead(a) {
      console.log(a)
      const { data } = await file({
        fileName: a.file.name
      })
      console.log(data)
      data.data.body.file = a.file
      const formData = new FormData()
      for (const key in data.data.body) {
        formData.append(key, data.data.body[key])
      }
      axios({
        method: 'post',
        url: data.data.post,
        data: formData
      }).then(res => {
        console.log(res)
        if (res.status === 200) {
          this.filedata.push(data.data.url)
        }
      })
    },
    async getAreaSubscribeDetail() {
      const { data } = await getAreaSubscribeDetail({
        id: this.id
      })
      console.log(data)
      let arr = data.data.facilities
      this.areaImg = data.data.areaImg
      this.areaTitle = data.data.areaTitle
      this.openStartTime = data.data.openStartTime
      this.openEndTime = data.data.openEndTime
      this.other = data.data.other
      this.facilities = data.data.facilities
      this.measureArea = data.data.measureArea
      this.capacity = data.data.capacity
      this.areaPhone = data.data.areaPhone
      this.isFile = data.data.isFile
      this.link = data.data.vrLink
      this.getSubscribeTypeList()
      arr = arr.split('、')
      this.facility = arr.map(res => {
        return { value: res, sel: false }
      })
    },
    async getSubscribeTypeList() {
      const { data } = await getSubscribeTypeList()
      this.bookedtype = data.data.map(res => {
        return { value: res, sel: false }
      })
    },
    seldata() {
      if (this.rq !== '请选择日期') {
        this.currentDate = new Date(this.rq)
      }
      this.show3 = true
    },
    selectype() {
      this.show = true
    },
    selecFacility() {
      this.show1 = true
    },
    seltime() {
      if (this.rq === '请选择日期') {
        Toast.fail('请先选择日期')
      } else {
        this.show4 = true
      }
    },
    seltype(row) {
      this.bookedtype.forEach((res, index) => {
        if (row === index) {
          res.sel = true
        } else {
          res.sel = false
        }
      })
    },
    selfacility(row) {
      this.facility.forEach((res, index) => {
        if (row === index) {
          res.sel = true
        }
      })
    },
    submittype() {
      this.show = false
      this.bookedtype.forEach(res => {
        if (res.sel) {
          this.lx = res.value
        }
      })
    },
    submitfacility() {
      this.show1 = false
      let str = ''
      this.facility.forEach(res => {
        if (res.sel) {
          str += res.value + '、'
        }
      })
      this.sb = str.substring(0, str.length - 1)
    },
    xztime(row) {
      this.datetime.forEach((res, index) => {
        if (row === index && res.status !== 1) {
          this.ww = !this.ww
          if (res.sell) {
            res.sell = false
          } else {
            res.sell = true
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.dele {
  position: absolute;
  top: 0%;
  right: 0%;
  z-index: 99;
}
/deep/ .van-cell {
  line-height: 26px;
}
/deep/ .van-field__control {
  overflow: hidden;
}
.xxx {
  font-size: 14px;
  color: #ee0a24;
}
#hz {
  background-color: #aaa;
  color: #fff;
}
.sell {
  color: #fff;
  background-color: #f8892f !important;
}
.time-top {
  color: #999;
  font-size: 12px;
  text-align: center;
}
.rqsj {
  margin: 5px 0;
  font-size: 14px;
}
/deep/ .van-action-sheet__header {
  line-height: 36px;
}
.date-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .no {
    background-color: #e5e5e5;
    color: #333333;
  }
  div {
    width: 35vw;
    height: 42px;
    line-height: 42px;
    text-align: center;
    color: #fff;
    background-color: #f8892f;
    border-radius: 19px;
    font-size: 14px;
    margin: 25px 10px 15px;
  }
  .timebut {
    margin: 10px 10px 0 !important;
  }
}

/deep/ .van-picker__frame {
  border-left: 2px solid #f8892f;
}
/deep/ .van-picker__toolbar {
  height: 20px;
  margin-bottom: 20px;
}
.date-top {
  margin: 0 auto;
}
/deep/ .van-action-sheet__header {
  font-size: 14px;
  color: #333333;
  font-weight: bold;
}
.tent {
  padding: 0 16px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .tent-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 90vw;
    div {
      font-size: 14px;
      margin-right: 3vw;
      margin-bottom: 10px;
      width: 27vw;
      height: 43px;
      background-color: #efefef;
      border-radius: 8px;
      line-height: 43px;
      text-align: center;
    }
    .sel {
      background-color: #fff3eb;
      color: #f8892f;
      border: 1px solid #f8892f;
      width: calc(27vw - 2px);
      height: 41px;
      line-height: 41px;
    }
    .box-time {
      width: 21vw;
      margin-right: 1.5vw;
    }
  }
  .tent-but {
    width: 90vw;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
    background-color: #f8892f;
    margin-top: 10px;
  }
}
.tet {
  width: 100%;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 28px;
  img {
    width: 10px;
  }
  .tet-img {
    margin-left: 20px;
  }
}
.pf {
  font-size: 14px;
  color: #646566;
  margin-left: 20vw;
  span {
    color: #323232;
    margin-left: 10px;
  }
}
.content {
  .updata .van-cell::after {
    border: none;
  }
  .updata {
    > div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      img {
        height: 50px;
      }
      .file-box {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .filename {
      font-size: 14px;
      color: #969696;
      width: 70px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 15px;
      margin-bottom: 10px;
    }
  }
  .up {
    border-radius: 5px;
    width: 70px;
    height: 50px;
    border: 0.5px #969696 dashed;
    margin-left: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    width: 94%;
    margin-left: 3%;
    height: 50px;
    color: #fff;
    background-color: #f8892f;
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    border-radius: 25px;
    margin-top: 15px;
  }
  .message {
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
    width: 94%;
    margin-left: 3%;
    background-color: #fff;
    height: 120px;
    border-radius: 15px;
    span {
      font-size: 14px;
      color: #646566;
      margin-bottom: 5px;
    }
    /deep/ .van-cell {
      padding: 0;
    }
    /deep/ .van-cell__value {
      border: 0.5px #e7e7e7 solid;
      border-radius: 10px;
      padding: 5px;
      box-sizing: border-box;
    }
    /deep/ .van-field__word-limit {
      color: #e7e7e7;
    }
  }
  padding-bottom: 15px;
  height: 100%;
  width: 100%;
  /deep/ .van-cell-group--inset {
    width: 94%;
    margin: 0 0 10px 3%;
    .van-field__label {
      width: 5.2em;
      margin-right: 0px;
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border-radius: 12px;
    background-color: #fff;
    width: 94%;
    margin-left: 3%;
    height: 247px;
    overflow: hidden;
    .top {
      position: relative;
      .top-ck {
        position: absolute;
        bottom: 10%;
        left: 3%;
        display: flex;
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        font-size: 14px;
        line-height: 14px;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        height: 23px;
        width: 90px;
        img {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
      .top-phone {
        border: none;
        outline: none;
        height: 38px;
        width: 38px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 50% 50%;
        position: absolute;
        bottom: 6%;
        right: 3%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
    .img1 {
      width: 100%;
      height: 173px;
    }
    .bottom {
      height: 74px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 15px 10px 15px;
      box-sizing: border-box;
      .left {
        display: flex;
        flex-direction: column;
        align-self: left;
        .left-bom {
          display: flex;
          align-items: center;
        }
        img {
          width: 13px;
          height: 13px;
          margin-right: 3px;
        }
        .title {
          font-size: 18px;
          color: #333333;
          font-weight: bold;
          margin-bottom: 12px;
        }
        .time {
          font-size: 14px;
          color: #969696;
        }
      }
    }
    .but {
      font-size: 14px;
      border-radius: 16px;
      width: 100px;
      height: 34px;
      background-color: #f8892f;
      color: #fff;
      line-height: 34px;
      text-align: center;
      border: none;
      outline: none;
    }
  }
  /deep/ .van-cell {
    font-size: 15px;
  }
}
</style>
